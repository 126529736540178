import * as React from "react";
// import { graphql } from 'gatsby'

import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import CustomHead from "../components/common/Head";

// const query = graphql``

const HowItWorksPage = () => {
  return (
    <Layout>
      <Section title="How it works" intro="Coming soon..."></Section>
    </Layout>
  );
};

export const Head = () => {
  const pageMeta = {
    title: "How it works",
    description:
      "Find out all you need to know about how CodeCaravan works and what its projects, guidance and professional code reviews can do for you, your portfolio and your career as a developer",
  };

  return <CustomHead pageMeta={pageMeta} />;
};

export default HowItWorksPage;
